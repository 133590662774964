const data = [
  {
    haftaufweitungHq: 1,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 2,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 3,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 4,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 5,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 6,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 7,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 8,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 9,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 10,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 11,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 12,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 13,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 14,
    aufweitDurchmesser: 6,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 15,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 11
  },
  {
    haftaufweitungHq: 16,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 17,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 18,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 19,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 20,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 21,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 22,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 23,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 24,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 25,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 26,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 27,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 28,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 29,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  },
  {
    haftaufweitungHq: 30,
    aufweitDurchmesser: 2,
    aufweitDurchmesserDin: 2
  }
];

export default data;
