import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import de from "react-intl/locale-data/de";
import translations_de from "./translations/de.json";
import translations_en from "./translations/en.json";
import Formular from "./components/Formular";

function Rechner() {
  addLocaleData([...en, ...de]);

  const translations = {
    de: translations_de,
    en: translations_en
  };

  const language = navigator.language.split(/[-_]/)[0]; // language without region code

  return (
    <IntlProvider locale={language} messages={translations[language]}>
      <div className="tabellen__tool">
        <Formular />
      </div>
    </IntlProvider>
  );
}

export default Rechner;
