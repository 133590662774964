import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

export default class Formular extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bohrung: 30.4,
      wandstaerke: 3,
      haftaufweitungHq: 20
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        console.log("new state", this.state);
      }
    );
  };

  render() {
    return (
      <div className="formular__container">
        <div className="formular__inner">
          <div className="formular__inner--left">
            <div>
              <FormattedMessage
                id="rechner.bohrung"
                defaultMessage="{bohrung}"
                description="Welcome header on app main page"
                values={{ bohrung: "Bohrung im Boden :" }}
              />
              <label>
                <input
                  type="number"
                  name="bohrung"
                  id="bohrung"
                  value={this.state.bohrung || 0}
                  onChange={this.handleChange}
                  min="0"
                  max="300"
                  inputMode="numeric"
                  step=".01"
                />{" "}
                mm
              </label>
            </div>
            <div>
              <span>
                <p>
                  - 2x{" "}
                  <label>
                    <input
                      type="number"
                      name="wandstaerke"
                      id="wandstaerke"
                      value={this.state.wandstaerke || 0}
                      onChange={this.handleChange}
                      min="0"
                      max="300"
                      inputMode="numeric"
                      step=".01"
                      pattern="[0-9]*"
                    />
                  </label>{" "}
                  mm Wandstärke:
                </p>
              </span>
              <span>
                <p>{(this.state.wandstaerke * 2).toFixed(2)} mm</p>
              </span>
            </div>
            <div>
              <span>
                <p>theor.Innen-ø des Rohres bei Anlage in der Bohrung:</p>
              </span>
              <span style={{ borderBottom: "1px solid #333" }}>
                <p>
                  {(this.state.bohrung - this.state.wandstaerke * 2).toFixed(2)}{" "}
                  mm
                </p>
              </span>
            </div>
            <div>
              <span style={{ width: "50%" }}>
                <p>
                  zzgl. z.B.{" "}
                  <label>
                    <input
                      type="number"
                      name="haftaufweitungHq"
                      id="haftaufweitungHq"
                      value={this.state.haftaufweitungHq || 0}
                      onChange={this.handleChange}
                      min="0"
                      max="300"
                      inputMode="numeric"
                      step="1"
                    />
                  </label>{" "}
                  % Haftaufweitung bezogen auf die Wandstärke des Rohres (
                  {this.state.haftaufweitungHq}% von{" "}
                  {this.state.wandstaerke > 0 ? this.state.wandstaerke : 0}mm):
                </p>
              </span>
              <span>
                <p style={{ marginRight: "15px" }} />
                <p style={{ borderBottom: "1px solid #333" }}>
                  +{" "}
                  {(
                    (this.state.wandstaerke * this.state.haftaufweitungHq) /
                    100
                  ).toFixed(2)}{" "}
                  mm
                </p>
              </span>
            </div>
            <div>
              <span>
                <p>theor.Innen-ø des Rohres nach dem Dichtwalzen:</p>
              </span>
              <span style={{ borderBottom: "6px double #333" }}>
                <p>
                  {(
                    this.state.bohrung -
                    this.state.wandstaerke * 2 +
                    (this.state.wandstaerke * this.state.haftaufweitungHq) / 100
                  ).toFixed(2)}{" "}
                  mm
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
