import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

export default class HQCalc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bohrung: this.props.bo,
      rohraussen: this.props.ra,
      wandstaerke: this.props.wa
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () =>
        this.props.changeData(
          this.bohrung.value,
          this.rohraussen.value,
          this.wandstaerke.value
        )
    );
  }

  render() {
    return (
      <div className="tabelle__form">
        <label>
          <FormattedMessage
            id="formular.bohrung"
            defaultMessage="{bohrung}"
            description="Welcome header on app main page"
            values={{ bohrung: "Bohrung-ø (Bo):" }}
          />
          <input
            type="number"
            name="bohrung"
            id="bohrung"
            value={this.state.bohrung || 0}
            onChange={this.handleChange}
            ref={bohrung => {
              this.bohrung = bohrung;
            }}
            min="0"
            max="300"
            inputMode="numeric"
            step=".01"
          />{" "}
          mm
        </label>
        <label>
          <FormattedMessage
            id="formular.rohraussen"
            defaultMessage="{rohraussen}"
            description="Welcome header on app main page"
            values={{ rohraussen: "Rohr-ø außen (Ra):" }}
          />
          <input
            type="number"
            name="rohraussen"
            id="rohraussen"
            value={this.state.rohraussen || 0}
            onChange={this.handleChange}
            ref={rohraussen => {
              this.rohraussen = rohraussen;
            }}
            min="0"
            max="300"
            inputMode="numeric"
            step=".01"
          />{" "}
          mm
        </label>
        <label>
          <FormattedMessage
            id="formular.wandstaerke"
            defaultMessage="{wandstaerke}"
            description="Welcome header on app main page"
            values={{ wandstaerke: "Wandstärke (Wa):" }}
          />
          <input
            type="number"
            name="wandstaerke"
            id="wandstaerke"
            value={this.state.wandstaerke || 0}
            onChange={this.handleChange}
            ref={wandstaerke => {
              this.wandstaerke = wandstaerke;
            }}
            min="0"
            max="300"
            inputMode="numeric"
            step=".01"
          />{" "}
          mm
        </label>
      </div>
    );
  }
}
