import React, { Component } from "react";
import ReactTable from "react-table";
import update from "immutability-helper";
import "react-table/react-table.css";
import HQCalc from "./HQCalc";
import data from "./data";
import { FormattedMessage } from "react-intl";

export default class Haftaufweitung extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sorted: [],
      page: 0,
      pageSize: 10,
      expanded: {},
      resized: [],
      filtered: [],
      sortable: false,
      data: data,
      bohrung: 15.25,
      rohraussen: 15,
      wandstaerke: 2.2
    };

    this.changeData = this.changeData.bind(this);
  }

  componentDidMount() {
    const newData = this.state.data.map((item, index) =>
      update(this.state.data, {
        aufweitDurchmesser: {
          $set:
            this.calcKonv(
              this.state.bohrung,
              this.state.wandstaerke,
              this.state.data[index].haftaufweitungHq
            ) || 0
        },
        aufweitDurchmesserDin: {
          $set:
            this.calcDin(
              this.state.bohrung,
              this.state.rohraussen,
              this.state.wandstaerke,
              this.state.data[index].haftaufweitungHq
            ) || 0
        },
        haftaufweitungHq: {
          $set: this.state.data[index].haftaufweitungHq
        }
      })
    );

    this.setState({
      data: newData
    });
  }

  changeData = (bohrung, rohraussen, wandstaerke) => {
    const newData = this.state.data.map((item, index) =>
      update(this.state.data, {
        aufweitDurchmesser: {
          $set: this.calcKonv(
            bohrung,
            wandstaerke,
            this.state.data[index].haftaufweitungHq
          )
        },
        aufweitDurchmesserDin: {
          $set: this.calcDin(
            bohrung,
            rohraussen,
            wandstaerke,
            this.state.data[index].haftaufweitungHq
          )
        },
        haftaufweitungHq: {
          $set: this.state.data[index].haftaufweitungHq
        }
      })
    );

    this.setState({
      data: newData
    });
  };

  calcKonv = (bohrung, wandstaerke, hq) => {
    const ergKonv = bohrung - 2 * wandstaerke + (hq / 100) * wandstaerke;
    return isNaN(ergKonv) || Number.isInteger(ergKonv.toFixed(2))
      ? 0
      : ergKonv.toFixed(2);
  };

  calcDin = (bohrung, rohraussen, wandstaerke, hq) => {
    const Ra = rohraussen;
    const Bo = bohrung;
    const Wa = wandstaerke;

    const ergDin = Math.sqrt(
      Math.pow(Bo, 2) -
        (Math.pow(Ra, 2) - Math.pow(Ra - Wa * 2, 2)) * (1 - hq / 100)
    );

    return isNaN(ergDin) || Number.isInteger(ergDin.toFixed(2))
      ? 0
      : ergDin.toFixed(2);
  };

  render() {
    const columns = [
      {
        columns: [
          {
            Header: (
              <FormattedMessage
                id="table.haftaufweitungHq"
                defaultMessage="{haftaufweitungHq}"
                description="Table Header Haftaufweitung HQ"
                values={{ haftaufweitungHq: "Haftaufweitung HQ" }}
              />
            ),
            accessor: "haftaufweitungHq", // String-based value accessors!
            Cell: props => (
              <span className="number">
                {props.value > 0 ? props.value : 0}%
              </span>
            ) // Custom cell
          }
        ]
      },
      {
        Header: (
          <FormattedMessage
            id="table.konv"
            defaultMessage="{konv}"
            description="Welcome header on app main page"
            values={{ konv: "Konventionelle Methode" }}
          />
        ),
        columns: [
          {
            Header: (
              <FormattedMessage
                id="table.aufweitDurch"
                defaultMessage="{aufweitDurch}"
                description="Table Header Aufweitdurchmesser"
                values={{ aufweitDurch: "Aufweit-ø in mm" }}
              />
            ),
            accessor: "aufweitDurchmesser",
            Cell: props => (
              <span className="number">
                {props.value > 0 ? props.value : 0}
              </span>
            ) // Custom cell components!
          }
        ]
      },
      {
        Header: (
          <FormattedMessage
            id="table.methodeDin"
            defaultMessage="{methodeDin}"
            description="Table Header Methode nach DIN 28187"
            values={{ methodeDin: "Methode nach DIN 28187" }}
          />
        ),
        columns: [
          {
            Header: (
              <FormattedMessage
                id="table.aufweitDurch"
                defaultMessage="{aufweitDurch}"
                description="Table Header Aufweitdurchmesser"
                values={{ aufweitDurch: "Aufweit-ø in mm" }}
              />
            ),
            accessor: "aufweitDurchmesserDin",
            Cell: props => (
              <span className="number">
                {props.value > 0 ? props.value : 0}
              </span>
            ) // Custom cell components!
          }
        ]
      }
    ];

    return (
      <>
        <HQCalc
          data={this.state.data}
          changeData={this.changeData}
          bo={this.state.bohrung}
          ra={this.state.rohraussen}
          wa={this.state.wandstaerke}
        />
        <ReactTable
          data={this.state.data}
          columns={columns}
          showPagination={false}
          defaultPageSize={30}
          style={{
            height: "600px"
          }}
        />
      </>
    );
  }
}
